import { List } from 'antd';
import type { CSSProperties } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ModuleIcon } from './moduleIcon';
import './moduleListItem.css';

interface moduleItem {
  item: any;
  showType?: boolean;
  style?: CSSProperties;
  url?: string;
}

export const ModuleListItem: React.FC<moduleItem> = ({
  item,
  showType = false,
  style,
  url,
}) => {
  const type = item.type;
  let link_url: string | null = null;
  let title: string | null = null;
  let description: string | null = null;

  switch (type) {
    case 'Account':
      link_url = `/dashboard/accounts/${item.id}`;
      title = item.name;

      description = `${item.country || ''}${item.country ? ', ' : ''}${item.state || ''
        }`.trim();
      break;
    case 'Deal':
      link_url = `/dashboard/deals/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Contact':
      link_url = `/dashboard/contacts/${item.id}`;
      title = item.full_name;
      description = item.email;
      break;
    case 'AccountContact':
      link_url = `/dashboard/accounts/${item.account.id}`;
      title = item.account.name;
      description = item.contact.full_name;
      break;
    case 'Lead':
      link_url = `/dashboard/leads/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Campaign':
      link_url = `/dashboard/campaigns/${item.id}`;
      title = item.name;
      break;
    case 'StatsDesignCode':
      link_url = `/dashboard/designcodes/${item.id}`;
      title = item.id;
      description = item.name;
      break;
    case 'Design':
      link_url = `/dashboard/designcodes/${item.stats_designcode_id}`;
      title = item.code;
      description = item.name;
      break;
    case 'ArtCode':
      link_url = `/dashboard/artcodes/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Style':
      link_url = `/dashboard/styles/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Forecast':
      link_url = `/dashboard/deals/${item.deal.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'ArtJob':
      link_url = `/dashboard/artjobs/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'TeamStore':
      link_url = `/dashboard/teamstores/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'PurchaseOrder':
      link_url = `/dashboard/purchaseorders/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'SalesReceipt':
      link_url = `/dashboard/salesorders/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Invoice':
      link_url = `/dashboard/invoices/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'NpsSurvey':
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Case':
      link_url = `/dashboard/cases/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
    case 'Task':
      link_url = `/dashboard/tasks/${item.id}`;
      title = item.name;
      description = item.account ? item.account.name : null;
      break;
  }

  if (description === '') description = null;

  if (showType) {
    description = `${type}${description ? ' | ' : ''}${description || ''}`;
  }

  if (url) {
    link_url = url;
  }

  return (
    <Link to={link_url || '#'}>
      <div className="ant-list-item" style={style}>
        <div className="module-tag-icon">
          <ModuleIcon colorBackground={true} type={type} />
        </div>
        <List.Item.Meta title={title} description={description} />
      </div>
    </Link>
  );
};
