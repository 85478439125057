import { asyncComponent, asyncNamedComponent } from '@app/helpers/AsyncFunc';

export const routes = [
  {
    path: '',
    component: asyncNamedComponent(async () => {
      const { Widgets } = await import('../Widgets');
      return Widgets;
    }),
  },
  {
    path: 'userSettings',
    component: asyncNamedComponent(async () => {
      const { SingleUser } = await import('../Users/singleUser');
      return SingleUser;
    }),
  },
  {
    path: 'zoho/authorize',
    component: asyncComponent(() => import('../ThirdParty/zoho')),
  },
  {
    path: 'accounts/:accountId',
    component: asyncNamedComponent(async () => {
      const { SingleAccount } = await import('../Accounts/singleAccount');
      return SingleAccount;
    }),
  },
  {
    path: 'accounts',
    component: asyncNamedComponent(async () => {
      const { Accounts } = await import('../Accounts');
      return Accounts;
    }),
  },
  {
    path: 'deals/:dealId',
    component: asyncNamedComponent(async () => {
      const { SingleDeal } = await import('../Deals/singleDeal');
      return SingleDeal;
    }),
  },
  {
    path: 'deals',
    component: asyncNamedComponent(async () => {
      const { Deals } = await import('../Deals');
      return Deals;
    }),
  },
  {
    path: 'contacts/:contactId',
    component: asyncComponent(() => import('../Contacts/singleContact')),
  },
  {
    path: 'contacts',
    component: asyncNamedComponent(async () => {
      const { Contacts } = await import('../Contacts');
      return Contacts;
    }),
  },
  {
    path: 'campaigns',
    component: asyncNamedComponent(async () => {
      const { Campaigns } = await import('../Campaigns');
      return Campaigns;
    }),
  },
  {
    path: 'designcodes/:designcodeId',
    component: asyncNamedComponent(async () => {
      const { SingleDesigncode } = await import('../DesignCodes/single');
      return SingleDesigncode;
    }),
  },
  {
    path: 'designcodes',
    component: asyncNamedComponent(async () => {
      const { Designcodes } = await import('../DesignCodes');
      return Designcodes;
    }),
  },
  {
    path: 'styles/:styleId',
    component: asyncNamedComponent(async () => {
      const { SingleStyle } = await import('../Styles/single');
      return SingleStyle;
    }),
  },
  {
    path: 'styles',
    component: asyncNamedComponent(async () => {
      const { Styles } = await import('../Styles');
      return Styles;
    }),
  },
  {
    path: 'forecasts',
    component: asyncNamedComponent(async () => {
      const { Forecasts } = await import('../Forecasts');
      return Forecasts;
    }),
  },
  {
    path: 'artjobs',
    component: asyncNamedComponent(async () => {
      const { ArtJobs } = await import('../ArtJobs');
      return ArtJobs;
    }),
  },
  {
    path: 'artjobs/:artJobId',
    component: asyncNamedComponent(async () => {
      const { SingleArtJob } = await import('../ArtJobs/single');
      return SingleArtJob;
    }),
  },
  {
    path: 'teamstores',
    component: asyncNamedComponent(async () => {
      const { TeamStores } = await import('../TeamStores');
      return TeamStores;
    }),
  },
  {
    path: 'teamstores/:teamstoreId',
    component: asyncNamedComponent(async () => {
      const { SingleTeamStore } = await import('../TeamStores/single');
      return SingleTeamStore;
    }),
  },
  {
    path: 'purchaseorders',
    component: asyncNamedComponent(async () => {
      const { PurchaseOrders } = await import('../PurchaseOrders');
      return PurchaseOrders;
    }),
  },
  {
    path: 'purchaseorders/:purchaseorderID',
    component: asyncNamedComponent(async () => {
      const { SinglePurchaseOrder } = await import('../PurchaseOrders/single');
      return SinglePurchaseOrder;
    }),
  },
  {
    path: 'purchaseorders/:purchaseOrderId/receiving',
    component: asyncNamedComponent(async () => {
      const { PurchaseOrderReceivingPage } = await import(
        '../PurchaseOrders/receivingPage'
      );
      return PurchaseOrderReceivingPage;
    }),
  },
  {
    path: 'purchases/create',
    component: asyncComponent(
      () => import('../PurchaseOrders/createPurchaseOrder')
    ),
  },
  {
    path: 'settings',
    component: asyncNamedComponent(async () => {
      const { Settings } = await import('../Settings');
      return Settings;
    }),
  },
  {
    path: 'snippets',
    component: asyncNamedComponent(async () => {
      const { Snippets } = await import('../Snippets');
      return Snippets;
    }),
  },
  {
    path: 'snippets/:snipId',
    component: asyncNamedComponent(async () => {
      const { SingleSnippet } = await import('../Snippets/single');
      return SingleSnippet;
    }),
  },
  {
    path: 'prodoTemplates',
    component: asyncNamedComponent(async () => {
      const { ProdoTemplates } = await import('../ProdoTemplates');
      return ProdoTemplates;
    }),
  },
  {
    path: 'prodoTemplates/:snipId',
    component: asyncNamedComponent(async () => {
      const { SingleProdoTemplate } = await import('../ProdoTemplates/single');
      return SingleProdoTemplate;
    }),
  },
  {
    path: 'npssurveys',
    component: asyncNamedComponent(async () => {
      const { NpsSurveys } = await import('../NpsSurveys');
      return NpsSurveys;
    }),
  },
  {
    path: 'artcodes',
    component: asyncNamedComponent(async () => {
      const { Artcodes } = await import('../Artcodes');
      return Artcodes;
    }),
  },
  {
    path: 'artcodes/:artcodeCode',
    component: asyncNamedComponent(async () => {
      const { SingleArtcode } = await import('../Artcodes/single');
      return SingleArtcode;
    }),
  },
  {
    path: 'supplierpo/:suppoid',
    component: asyncNamedComponent(async () => {
      const { SingleSupplierPurchaseOrder } = await import(
        '../SupplierPurchaseOrder/single'
      );
      return SingleSupplierPurchaseOrder;
    }),
  },
  {
    path: 'salesorders',
    component: asyncNamedComponent(async () => {
      const { SalesReceipts } = await import('../SalesReceipts');
      return SalesReceipts;
    }),
  },
  {
    path: 'salesorders/:salesOrderId/edit',
    component: asyncNamedComponent(async () => {
      const { SingleSalesReceiptEdit } = await import(
        '../SalesReceipts/singleEdit'
      );
      return SingleSalesReceiptEdit;
    }),
  },
  {
    path: 'salesorders/:salesOrderId',
    component: asyncNamedComponent(async () => {
      const { SingleSalesReceipt } = await import('../SalesReceipts/single');
      return SingleSalesReceipt;
    }),
  },
  {
    path: 'bin/reconcile',
    component: asyncNamedComponent(async () => {
      const { ReconcileBin } = await import('../Warehouse/reconcileBin');
      return ReconcileBin;
    }),
  },
  {
    path: 'testing/scans',
    component: asyncNamedComponent(async () => {
      const { ScanTest } = await import('../Testing/scans');
      return ScanTest;
    }),
  },
  {
    path: 'resources',
    component: asyncNamedComponent(async () => {
      const { ResourcePage } = await import('../ResourceCenter');
      return ResourcePage;
    }),
  },
  {
    path: 'resources/edit',
    component: asyncNamedComponent(async () => {
      const { ResourceEditor } = await import('../ResourceCenter/editor');
      return ResourceEditor;
    }),
  },
];
